<template>
  <div
    class="text-black flex flex-col relative py-8 md:py-0"
    :class="{
      'items-center': alignment === 'center',
      'items-start': alignment === 'start',
    }"
  >
    <div v-if="state === 'ENTER_CODE'">
      <header class="text-xl md:text-2xl font-light">
        <strong class="font-medium">{{ $t('title') }} </strong>

        <br class="md:hidden" />{{ $t(type) }}
      </header>

      <p v-purify="$t('sentTo', { identity: mask ? mask : identity })" class="mt-4 text-gray-700" />

      <div v-if="!disableChangePhoneNumber && type === 'phone'" class="mt-4 flex whitespace-nowrap">
        <span class="text-gray-700">{{ $t('notYourPhone') }}</span>

        <button class="underline block font-medium text-primary-1-100 ml-3" @click="emit('close')">
          {{ $t('changePhoneNumber') }}
        </button>
      </div>

      <div class="relative">
        <form
          dir="ltr"
          class="otp-input-container mt-9 grid grid-cols-4 gap-4 xs:gap-7 pb-8 mx-auto"
          :class="{ 'mx-auto': alignment === 'center' }"
          @keyup="handleMoveToNextInput"
          @keydown="handleInvalidNumberKeys"
        >
          <input
            v-for="(_, input) in inputs"
            :id="`otp-${input}`"
            :key="input"
            v-model="inputs[input]"
            class="transparent-selection bg-transparent outline-none text-center caret-transparent rounded-2xl [ h-25 md:h-35 w-full ] [ flex items-center justify-center ] [ lg:text-xl ]"
            :class="{
              'dashed-gradient focus:border-gray-90 focus:placeholder-gray-border-gray-90 text-primary-1-100': !(
                !touchedOtp && invalidOtp
              ),
              'border-red-700 text-red-700': !touchedOtp && invalidOtp,
            }"
            data-max-length="1"
            placeholder="_"
            type="number"
            :disabled="isVerifyingOtp"
            :aria-disabled="isVerifyingOtp"
            @input="touchedOtp = true"
            @focus="handleFocusOtpInput"
          />
        </form>
        <p v-if="!touchedOtp && invalidOtp" class="w-full text-center text-red-700 absolute bottom-0 left-0 text-sm">
          {{ $t('invalidOtp') }}
        </p>
      </div>

      <div class="inline-flex items-center text-gray-700">
        {{ $t('didntReceiveCode') }}

        <template v-if="isSendingVerificationCode || isVerifyingOtp">
          <Spinner class="mx-auto w-6 h-6" />
        </template>
        <button
          v-else
          type="button"
          class="underline text-primary-1-100 font-medium ml-3"
          :disabled="!!activeTimer"
          :aria-disabled="!!activeTimer"
          @click="handleResendCode"
        >
          {{ $t('resendCode') }}
          <span v-if="activeTimer">({{ `${activeTimer} ${$t('secondsLeft')}` }})</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CombinedError } from 'villus';
import { useExtendedSendVerificationCode } from '~/composables/auth';
import { OTPContext } from '~/utils/types';

type State = 'ENTER_CODE' | 'UPDATE_IDENTITY';
defineComponent({
  /**
   * emits:
   * identity: the value represents the updated value triggered from update phone number ( phone number only )
   */
  name: 'VerifyOtp',
});

const props = defineProps({
  /**
   * @description the identity string could be an email|phone number to be verified
   */
  value: {
    type: String,
    required: true,
  },
  disableChangePhoneNumber: {
    type: Boolean,
    default: false,
  },
  validationOnly: { type: Boolean, default: false },
  mask: { type: String, default: '' },

  alignment: {
    type: String,
    required: false,
    validator: (value: string) => {
      return ['center', 'start'].includes(value);
    },
    default: 'start',
  },
  context: {
    type: String,
    default: OTPContext.REGISTER,
  },
});

const emit = defineEmits(['success', 'error', 'close']);

const identity = ref(props.value);
const state = ref<State>('ENTER_CODE');
const isForgetPassword = computed(() => props.context === OTPContext.FORGET);

const touchedOtp = ref(false);
const invalidOtp = ref(false);

const { reSendVerificationCode, isFetching: isSendingVerificationCode } = useExtendedSendVerificationCode(
  identity.value,
  isForgetPassword.value,
);

const { inputs, phoneVerification } = usePhoneVerification();
const { error, success } = useAlerts();
const { t: $t } = useI18n({
  useScope: 'local',
});

const { t } = useI18n();
const { ExceptionsMap } = useExceptions('otpVerification');

const { verifyOtp, isFetching: isVerifyingOtp } = useVerifyOtp(identity);
let activeInterval: ReturnType<typeof setInterval> | null = null;
const activeTimer = ref<number | null>(null);

const type: ComputedRef<'email' | 'phone' | 'something'> = computed(() => {
  if (isEmail(identity.value)) {
    return 'email';
  }

  if (isPhone(identity.value)) {
    return 'phone';
  }

  return 'something';
});

function startResendTimer() {
  stopResendTimer();
  activeTimer.value = 30; // seconds
  activeInterval = setInterval(() => {
    if (typeof activeTimer.value !== 'number') {
      return;
    }
    activeTimer.value--;
    if (activeTimer.value <= 0) {
      stopResendTimer();
    }
  }, 1000); // every second
}

function stopResendTimer() {
  if (activeInterval) {
    clearInterval(activeInterval);
  }
  activeInterval = null;
  activeTimer.value = null;
}

onMounted(() => {
  startResendTimer();
});

async function handleResendCode() {
  try {
    await reSendVerificationCode(identity.value);
    success(t('otp').toString(), t('otpCodeSent').toString());
    startResendTimer();
  } catch (err) {
    error(t('resendError').toString(), (err as Error).message.replace('[GraphQL] ', ''));
    stopResendTimer();
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onEditPhoneNumberSubmit() {
  try {
    throw new Error('Edit phone number is not supported');
    // await editPhoneNumber(props.value, identity.value);
    // emit('input', identity.value);
    // state.value = 'ENTER_CODE';
  } catch (err) {
    if (/\[GraphQL\] A customer exists with the new phone number\./.test((err as CombinedError).message)) {
      error('phone', 'A customer exists with the new phone number.');
    }
  }
}

watch(phoneVerification, async value => {
  if (!value) {
    return;
  }

  try {
    await verifyOtp(value, !props.validationOnly /** force login */);
    emit('success', value);
  } catch (e) {
    if ((e as Error)?.message === ExceptionsMap.otpVerification.wrongOtp.key) {
      error(type.value, 'Make sure you typed a valid OTP');
      touchedOtp.value = false;
      invalidOtp.value = true;
      return;
    }
    error('error', (e as CombinedError).message);
    touchedOtp.value = false;
    invalidOtp.value = false;
    emit('error');
  }
});
</script>

<style lang="postcss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.transparent-selection::selection {
  background: transparent;
}

.otp-input-container {
  max-width: 425px;
  @screen md {
    max-width: unset;
  }
}

.highlight {
  @apply relative inline z-0;
}
.highlight::after {
  content: '';
  height: 45px;
  width: 145px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -10px;
  right: 0;
  z-index: -1;
  @screen lg {
    height: 75px;
    width: 230px;
    right: -24px;
    bottom: -15px;
  }
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.dashed-gradient {
  border: 2px solid transparent;
  border-image: repeating-linear-gradient(119deg, black, black 5px, transparent 5px, transparent 10px) 1;
}
</style>

<i18n>
{
  "en": {
    "title": "Please Verify Your ",
    "number": "Number",
    "email": "Email",
    "phone": "Phone Number",
    "sentTo": "A code was sent to {identity}, enter the code below",
    "notYourPhone": "Not your phone number? ",
    "changePhoneNumber": "Change Phone Number",
    "didntReceiveCode":"Didn't receive code?",
    "resendCode": "Resend Code",
    "otpCodeSent": "OTP code Sent"
  },
  "ar": {
    "title": "التحقق من ",
    "number": "رقم الهاتف",
    "email": "البريد الألكتروني",
    "phone": "رقم الهاتف",
    "sentTo": "تم إرسال رمز التحقق إلى {identity}، أدخل الرمز أدناه",
    "notYourPhone": "ليس رقم هاتفك؟ ",
    "changePhoneNumber": "تغيير رقم الهاتف",
    "didntReceiveCode":"لم تتلقى الرمز؟",
    "resendCode": "إعادة إرسال الرمز",
    "otpCodeSent": "تم ارسال ال OTP"

  }
}
</i18n>
