import { useMutation } from 'villus';
import { ResendVerificationCodeDocument } from '~/graphql/Auth';

export function useExtendedSendVerificationCode(phoneNumber: string, isForgetPassword: boolean) {
  const { execute, isFetching } = useMutation(ResendVerificationCodeDocument);

  async function reSendVerificationCode(phoneNumber: string) {
    const { error } = await execute({ phoneNumber, isForgetPassword });
    if (error) throw error;
  }

  return {
    reSendVerificationCode,
    isFetching,
  };
}
