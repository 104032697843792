<template>
  <div>
    <VariantModalDialog v-model="isOpen" :close-button="false">
      <VerifyOtp
        :value="identity"
        :readonly="readonly"
        :validation-only="validationOnly"
        :mask="mask"
        :context="context"
        class="px-6"
        @success="otp => handleOtpSuccess(otp)"
        @close="isOpen = false"
      />
    </VariantModalDialog>
  </div>
</template>
<script setup lang="ts">
import { OTPContext } from '~/utils/types';

/**
 * verify OTP dialog
 * listens for verify_otp event
 * accepts the following
 *  - identity string phone | email
 *  = readonly or not ( incase of phone type )
 *
 * emits
 * success: callback from verify otp component
 * error: callback from from verify otp component
 *
 */
defineComponent({
  name: 'VerifyOtpDialog',
});

const isOpen = ref(false);
const identity = ref('');
const readonly = ref(false);
const validationOnly = ref(false);
const mask = ref('');
const context = ref<OTPContext>(OTPContext.REGISTER);

const { emit } = useEventBus();

useEvent(
  'ENTER_OTP',
  ({ identityString, readonly: _readonly, validationOnly: _validationOnly, mask: _mask, context: _context }) => {
    identity.value = identityString;
    readonly.value = _readonly ?? false;
    isOpen.value = true;
    validationOnly.value = !!_validationOnly;
    mask.value = ((_mask?.length || Number.MAX_VALUE) <= 3 ? `*********${_mask}` : _mask) || '';
    context.value = _context;
  },
);

function handleOtpSuccess(otp: string) {
  emit('OTP_AUTHENTICATED', { otp });
  isOpen.value = false;
}
</script>
